import first from 'lodash/first'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import replace from 'lodash/replace'
import { Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import ConditionsTable, { ConditionData, modelExists } from '../components/tagging/ConditionsTable'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import TimeFrames, { TimeFrame } from '../components/common/timeFrames'
import Training from '../components/metrics/training'
import { conditionsSelector, ConditionsState, fetchConditionPredictionCountsAction } from '../hasura/slices/conditions'
import { consultationsSelector, ConsultationsState, fetchConsultationsForConditionsPageAction } from '../hasura/slices/consultations'
import { emojiFor, Species } from '../lib/helpers'
import { fetchMLServerStatusAction } from '../hasura/slices/machine-learning'
import { getPossibleConditions } from '../lib/aiHelpers'
import { usersSelector, UsersState } from '../hasura/slices/users'

export interface Dictionary<T> {
  [key: string]: T
}

export interface TagCounts {
  date: string
  vet: string
  predictions: Dictionary<number>
  imageIds: number[]
}

export default function Metrics() {
  const dispatch = useDispatch()

  const { accessToken }: UsersState = useSelector(usersSelector)
  const { conditionPredictionCounts }: ConditionsState = useSelector(conditionsSelector)
  const { consultationsForAiComparison, conditions }: ConsultationsState = useSelector(consultationsSelector)

  const [activeTab, setActiveTab] = useState('training')
  const [species, setSpecies] = useState(Species.Dog)
  const [tableData, setTableData] = useState<ConditionData[] | undefined>()
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(TimeFrame.TwoWeeks)

  /* 
    Effects
  */

  useEffect(() => {
    if (!conditionPredictionCounts.length || !conditions.length) return

    const tableData = conditionPredictionCounts.map((condition) => {
      const name = condition.display_name
      const id = condition.id
      const wandbLink = `https://wandb.ai/radimal-team/${replace(name.toLowerCase(), ' ', '_')}-${species}`
      const exists = modelExists(species, condition.ml_name)
      const consultationPredictions = exists
        ? (consultationsForAiComparison || [])
            .filter((c) => c.case.patient.species === species)
            .map((c) => {
              const doctorPositive = c.predictions.some((p) => p.condition?.id === condition.id)
              const aiPositive = getPossibleConditions(c.case, conditions).some((a) => a.id === condition.id)
              return [doctorPositive, aiPositive]
            })
        : []

      const truePositives = consultationPredictions.filter((p) => p[0] && p[1]).length
      const trueNegatives = consultationPredictions.filter((p) => !p[0] && !p[1]).length
      const falsePositives = consultationPredictions.filter((p) => !p[0] && p[1]).length

      const aiPositives = consultationPredictions.filter((p) => p[1]).length
      const doctorPositives = consultationPredictions.filter((p) => p[0]).length

      const recall = exists ? `${truePositives}/${doctorPositives}` : '-'
      const recallAccuracy = doctorPositives === 0 ? 0 : Math.round((100 * truePositives) / doctorPositives) || 0
      const precision = exists ? `${truePositives}/${aiPositives}` : '-'
      const precisionAccuracy = aiPositives === 0 ? 0 : Math.round((100 * truePositives) / aiPositives) || 0
      const specificity = exists ? `${trueNegatives}/${trueNegatives + falsePositives}` : '-'
      const specificityAccuracy = exists ? Math.round((100 * trueNegatives) / (trueNegatives + falsePositives)) : 0

      return {
        name,
        ml_name: condition.ml_name,
        activeLearningCatPriority: condition.active_learning_cat_priority,
        activeLearningDogPriority: condition.active_learning_dog_priority,
        trainAndDeployCat: condition.train_and_deploy_cat,
        trainAndDeployDog: condition.train_and_deploy_dog,
        lastTrainedAtCat: condition.cat_model_last_trained_at,
        lastTrainedAtDog: condition.dog_model_last_trained_at,
        lastDeployedAtCat: first(condition.ml_models.filter((m) => ['cat', 'combined'].includes(m.species || '')))?.created_at,
        lastDeployedAtDog: first(condition.ml_models.filter((m) => ['dog', 'combined'].includes(m.species || '')))?.created_at,
        recall,
        recallAccuracy,
        precision,
        precisionAccuracy,
        specificity,
        specificityAccuracy,
        id,
        wandbLink,
        consultationPredictions:
          (species === 'dog'
            ? condition.dog_predictions_normalizeds_aggregate.aggregate?.count
            : condition.cat_predictions_normalizeds_aggregate.aggregate?.count) || 0,
      }
    })

    setTableData(tableData.filter((c) => c.consultationPredictions >= 0))
  }, [consultationsForAiComparison, conditionPredictionCounts, conditions, species])

  useEffect(() => {
    if (!accessToken) return

    fetchConsultations()
    dispatch(fetchConditionPredictionCountsAction(accessToken))
    dispatch(fetchMLServerStatusAction())
  }, [accessToken])

  useEffect(() => {
    fetchConsultations()
  }, [selectedTimeFrame])

  /* 
    Methods
  */
  const timeFrameNumber = parseInt(selectedTimeFrame, 10)
  const timeFrameType = selectedTimeFrame.endsWith('W') ? 'weeks' : 'months'

  const fetchConsultations = () => {
    const gt = moment().subtract(timeFrameNumber, timeFrameType)
    dispatch(fetchConsultationsForConditionsPageAction(accessToken, gt))
  }

  return (
    <Layout>
      <MainBox defaultPadding>
        <div className="min-height-400px">
          <Nav tabs className="mb-4">
            <NavItem>
              <NavLink className={`pointer ${activeTab === 'metrics' ? 'active' : ''}`} onClick={() => setActiveTab('metrics')}>
                Metrics
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={`pointer ${activeTab === 'training' ? 'active' : ''}`} onClick={() => setActiveTab('training')}>
                Training
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="training">
              <Row>
                <Training />
              </Row>
            </TabPane>

            <TabPane tabId="metrics">
              <Row>
                <div>
                  <div className="d-flex align-items-start justify-content-between">
                    <div>
                      <div className="d-flex mb-2">
                        <p className="width-80px bold mb-0 text-s">Species:</p>

                        <div className="d-flex">
                          {[Species.Dog, Species.Cat].map((s) => (
                            <span
                              key={s}
                              onClick={() => setSpecies(s)}
                              className={`${species === s ? '' : 'opacity-50'} pointer mr-4 text-m`}
                            >
                              {emojiFor(s)}
                            </span>
                          ))}
                        </div>
                      </div>

                      <div className="d-flex">
                        <p className="width-80px bold mb-0 text-s">Timeframe:</p>

                        <TimeFrames
                          options={[TimeFrame.TwoWeeks, TimeFrame.OneMonth, TimeFrame.TwoMonths]}
                          selectedTimeFrame={selectedTimeFrame}
                          setSelectedTimeFrame={setSelectedTimeFrame}
                        />
                      </div>
                    </div>
                  </div>

                  {tableData ? <ConditionsTable species={species} data={tableData} /> : <Spinner className="mt-2" color="primary" />}

                  {tableData && (
                    <p className="text-l m-0">
                      <span className="bold">{tableData.length}</span> conditions
                    </p>
                  )}
                </div>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </MainBox>
    </Layout>
  )
}
